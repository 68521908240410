import React from "react";

import { Container, Col, Row } from "reactstrap";
import mockup from "../../../img/Iphone.png";
import mockupDark from "../../../img/IphoneXS-dark.png";
import Content from "./Content/CTATypeFirst";
import { Fade } from "react-awesome-reveal";
import classes from "./CTALayoutsTypeFirst.module.css";

const title = "$155.49 Billion";
const description =
  "Without a doubt one of the single most important facets of success in the future of the betting & gaming market is understanding your users and delivering the quality user experience they demand.";
const subtitle = "The estimated global sports betting market value by 2024.";
const strong = "The Opportunity";

function CTALayoutsTypeFirst(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.cta} ${
          props.dark ? `${classes.dark}` : ""
        }`}
      >
        <Container>
          <Row className={classes.contentRow}>
            <Col lg={4} md={5} sm={9} xs={9} className={classes.leftColumn}>
              <Fade left triggerOnce={true}>
                <img
                  src={props.dark === true ? mockupDark : mockup}
                  alt=""
                  width="350"
                  height="350"
                />
              </Fade>
            </Col>

            <Col
              lg={{ size: 7, offset: 1 }}
              md={{ size: 7 }}
              sm={9}
              className={classes.rightColumn}
            >
              <div className={classes.gridWrapper}>
                <Content
                  title={title}
                  subtitle={subtitle}
                  description={description}
                  dark={props.dark ? true : false}
                >
                  <Fade top triggerOnce={true}>
                    <strong style={{ display: "block" }}>{strong}</strong>
                  </Fade>
                </Content>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default CTALayoutsTypeFirst;

import React, { Component } from "react";

import { Container, Col, Row } from "reactstrap";
import { FlashRounded, Favorite, Group } from "../../../components/Icons/Icons";
import Oval from "../../../components/Frame/Frame";
import Button from "../../../components/Button/Button";
import Feature from "./Feature/FeaturesTypeTwenty";
import { Fade } from "react-awesome-reveal";
import classes from "./FeaturesLayoutsTypeTwenty.module.css";

const title = "Vision & strategy at our heart";
const description =
  "An experience design agency based in Leeds, UK. We partner with enterprise businesses, small to medium-sized businesses and startups from all walks of life. ";

class FeaturesLayoutsTypeTwenty extends Component {
  state = {
    renderElement: 3,
    features: [
      {
        title: "Supercharged Team",
        description: "A expert distributed team with an expansive network.",
        icon: <FlashRounded fill={"#ffffff"} />,
        id: 1,
      },
      {
        title: "We ❤ Experience",
        description:
          "We\u0027re specialists in experience - it\u0027s the core of our business!",
        icon: <Favorite fill={"#ffffff"} />,
        id: 2,
      },
      {
        title: "Tech Aficionados",
        description: "Engineering \u0026 delivery with capacity for support.",
        icon: <Group fill={"#ffffff"} />,
        id: 3,
      },
    ],
  };

  buttonClickHandler = (event) => {
    event.preventDefault();
    const card = this.state.features;
    this.setState({ renderElement: card.length });
  };

  renderCards() {
    const card = this.state.features;
    const listItems = card
      .filter((element, index) => {
        if (index < this.state.renderElement) {
          return true;
        } else {
          return false;
        }
      })
      .map((item, index) => {
        return (
          <Col lg={4} md={4} className={classes.item} key={index}>
            <Fade down delay={`${index}0`} triggerOnce={true}>
              <Feature
                title={item.title}
                description={item.description}
                link={item.link}
                dark={this.props.dark ? true : false}
              >
                <Oval variant={"large"} color={"primary"}>
                  {item.icon}
                </Oval>
              </Feature>
            </Fade>
          </Col>
        );
      });
    return <>{listItems}</>;
  }

  render() {
    return (
      <React.Fragment>
        <section
          className={`section ${classes.features} ${
            this.props.dark ? `dark ${classes.dark}` : ""
          }`}
        >
          <div className={classes.gridWrapper}>
            <Container>
              <Row className={classes.headerRow}>
                <Col lg={10} md={8} className="mx-auto text-center">
                  <Fade down triggerOnce={true}>
                    <h1 className={`${classes.title} text-center`}>{title}</h1>
                  </Fade>
                  <Fade down delay={50} triggerOnce={true}>
                    <p className={`normal-text ${classes.description}`}>
                      {description}
                    </p>
                  </Fade>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className={classes.featuresRow}>{this.renderCards()}</Row>
            </Container>
            <Container>
              <Row className={classes.buttonRow}>
                <Col
                  lg={8}
                  md={8}
                  className="mx-auto text-center d-flex justify-content-center"
                >
                  {this.state.renderElement <= 3 && (
                    <Fade down delay={250} triggerOnce={true}>
                      <Button
                        link={true}
                        href={"#"}
                        classes={`primary large simple ${classes.button}`}
                        value={"See Our Work"}
                        onClick={this.buttonClickHandler}
                      />
                    </Fade>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default FeaturesLayoutsTypeTwenty;

import React from "react";

import Header from ".././components/Header/Header";
import PortfolioLayoutsTypeThird from ".././layouts/PortfolioLayouts/PortfolioLayoutsTypeThird/PortfolioLayoutsTypeThird";
import FeaturesLayoutsTypeFourteen from ".././layouts/FeaturesLayouts/FeaturesLayoutsTypeFourteen/FeaturesLayoutsTypeFourteen";
import FootersLayoutsTypeSecond from ".././layouts/FootersLayouts/FootersLayoutsTypeSecond/FootersLayoutsTypeSecond";
import CTALayoutsTypeSecond from ".././layouts/CTALayouts/CTALayoutsTypeSecond/CTALayoutsTypeSecond";
import TestimonialsLayoutsTypeSeventh from ".././layouts/TestimonialsLayouts/TestimonialsLayoutsTypeSeventh/TestimonialsLayoutsTypeSeventh";

function Work() {
  return (
    <div>
      <Header />
      <CTALayoutsTypeSecond />
      <PortfolioLayoutsTypeThird />
      <FeaturesLayoutsTypeFourteen />
      <TestimonialsLayoutsTypeSeventh />
      <FootersLayoutsTypeSecond />
    </div>
  );
}

export default Work;

import React from "react";
import classes from "./Header.module.css";
import { Nav, Navbar } from "react-bootstrap";
import { Container } from "reactstrap";
import { SprocketLogotypeLight } from "../../components/Icons/Icons";
import Button from "../../components/Button/Button";

const Header = (props) => {
  return (
    <React.Fragment>
      <Container>
        <section
          className={`${classes.header} ${
            props.dark ? `dark ${classes.dark}` : ""
          }`}
        >
          <Navbar collapseOnSelect expand="lg" className="p-0">
            <div className={classes.wrapper}>
              <Navbar.Brand href="/home">
                <SprocketLogotypeLight />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                className="custom-toggler"
              />
            </div>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link href="/our-services">Our Services</Nav.Link>
                <Nav.Link href="/betting">Betting & Gaming</Nav.Link>
                <Nav.Link href="/work">Our Work</Nav.Link>
              </Nav>
              <Button
                link={true}
                href={"/contact"}
                classes={"primary simple small "}
                value={"Contact"}
              />
              <br></br>
            </Navbar.Collapse>
          </Navbar>
        </section>
      </Container>
    </React.Fragment>
  );
};

export default Header;

import React from "react";
import classes from "./Aside.module.css";
import { Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";

const Aside = (props) => {
  return (
    <section className={classes.Aside}>
      <h2 className={classes.title}>{props.title}</h2>
      <p className={classes.description}>{props.description}</p>
      <Row className={classes.buttons}>
        <Col className={`col-auto pr-0 ${classes.col}`}>
          <Fade top delay={100} triggerOnce={true}>
            <a
              href="mailto:hello@sprocket.agency"
              className={`Button primary large simple ${classes.button}`}
            >
              <span>Email Us</span>
            </a>
          </Fade>
        </Col>
        <Col className={`col-auto ${classes.col}`}>
          <Fade top delay={100} triggerOnce={true}>
            <a
              href="tel:+44 20 8123 7275"
              className={`Button primary large simple ${classes.button}`}
            >
              <span>Call Us</span>
            </a>
          </Fade>
        </Col>
      </Row>

      <div className={classes.logos}>{props.children}</div>
    </section>
  );
};

export default Aside;

import React from "react";

import Header from ".././components/Header/Header";
import TestimonialsLayoutsTypeSeventh from ".././layouts/TestimonialsLayouts/TestimonialsLayoutsTypeSeventh/TestimonialsLayoutsTypeSeventh";
import FootersLayoutsTypeSecond from ".././layouts/FootersLayouts/FootersLayoutsTypeSecond/FootersLayoutsTypeSecond";
import FeaturesLayoutsTypeTwenty from ".././layouts/FeaturesLayouts/FeaturesLayoutsTypeTwenty/FeaturesLayoutsTypeTwenty";

function About() {
  return (
    <div>
      <Header />
      <FeaturesLayoutsTypeTwenty />
      <TestimonialsLayoutsTypeSeventh />
      <FootersLayoutsTypeSecond />
    </div>
  );
}

export default About;

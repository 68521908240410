import { Row, Col, Container } from "reactstrap";
import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import NavList from "../../../components/Nav/Nav";
import Oval from "../../../components/Frame/Frame";
import TextButton from "../../../components/Text-Button/Text-Button";

import placeholder from "../../../img/placeholder-gray.png";
import placeholderDark from "../../../img/placeholder-gray-dark.png";
import { ChevronDown, RightArrow } from "../../../components/Icons/Icons";
import classes from "./PortfolioLayoutsTypeThird.module.css";
import { Fade } from "react-awesome-reveal";

const postForView = 8;

class PortfolioLayoutsTypeThird extends Component {
  state = {
    activeCategory: "user experience",
    postForView: postForView,
    projects: [
      {
        name: "William Hill US",
        placeholder: "William Hill US",
        id: 1,
        link: "#/some-link#1",
        poster: null,
        latest: true,
        category: "user experience",
      },
      {
        name: "Pokerstars Sports",
        placeholder: "Pokerstars Sports",
        id: 2,
        link: "#/some-link#2",
        poster: null,
        latest: true,
        category: "user experience",
      },
      {
        name: "Foxbet",
        placeholder: "Foxbet",
        id: 3,
        link: "#/some-link#3",
        poster: null,
        latest: true,
        category: "user experience",
      },
      {
        name: "SkyBet Germany",
        placeholder: "SkyBet Germany",
        id: 4,
        link: "/contact",
        poster: null,
        latest: true,
        category: "user experience",
      },
      {
        name: "William Hill UK",
        placeholder: "William Hill UK",
        id: 5,
        link: "#/some-link#3",
        poster: null,
        latest: true,
        category: "branding",
      },
      {
        name: "Oqton",
        placeholder: "Oqton",
        id: 6,
        link: "#/some-link#3",
        poster: null,
        latest: true,
        category: "user interface",
      },
      {
        name: "cap hpi",
        placeholder: "cap hpi",
        id: 7,
        link: "#/some-link#1",
        poster: null,
        latest: true,
        category: "user interface",
      },
      {
        name: "William Hill US Sportsbooks",
        placeholder: "William Hill US Sportsbooks",
        id: 8,
        link: "#/some-link#2",
        poster: null,
        latest: true,
        category: "user research",
      },
    ],
    category: [
      "User Experience",
      "User Interface",
      "User Research",
      "Branding",
    ],
    subcategory: ["All"],
  };

  buttonClickHandler = (event) => {
    event.preventDefault();
    const card = this.state.projects;
    const listItems = card.filter((element) => {
      if (element.category === this.state.activeCategory) {
        return true;
      } else {
        return false;
      }
    });
    this.setState({ postForView: listItems.length });
  };

  onLinksClickHandler = (event) => {
    event.preventDefault();
    const currentValue = event.currentTarget.innerText.toLowerCase();
    this.setState({ activeCategory: currentValue });
    this.setState({ postForView: postForView });
  };

  renderCards() {
    const card = this.state.projects;
    const listItems = card
      .filter((element) => {
        if (element.category === this.state.activeCategory) {
          return true;
        } else {
          return false;
        }
      })
      .filter((element, index) => {
        if (index < this.state.postForView) {
          return true;
        } else {
          return false;
        }
      })
      .map((item, index) => {
        return (
          <Col
            lg="6"
            md="6"
            sm="12"
            xs="12"
            className={classes.item}
            key={index}
          >
            <Fade down delay={`${index}1`} triggerOnce={true}>
              <>
                <a href={item.link} className={classes.pictureLink}>
                  {item.poster ? (
                    <img
                      src={item.poster}
                      alt={item.placeholder}
                      width="350"
                      height="450"
                    />
                  ) : (
                    <img
                      src={
                        this.props.dark === true ? placeholderDark : placeholder
                      }
                      alt={item.placeholder}
                      width="350"
                      height="450"
                    />
                  )}
                </a>
              </>
              <Row className={classes.bottomLevel}>
                <Col
                  className={`col-auto ${classes.leftColumn} ${classes.col}`}
                >
                  <h5 href={item.link} className={`${classes.title} text-left`}>
                    {item.name}
                  </h5>
                </Col>
                <Col
                  className={`col-auto ml-auto ${classes.rightColumn} ${classes.col}`}
                >
                  <a href={item.link} className={classes.arrowLink}>
                    <Oval variant="small">
                      <RightArrow />
                    </Oval>
                  </a>
                </Col>
              </Row>
            </Fade>
          </Col>
        );
      });
    return <>{listItems}</>;
  }

  render() {
    return (
      <div>
        <section
          className={`section ${classes.nav} ${
            this.props.dark ? `dark ${classes.dark}` : ""
          }`}
        >
          <div className={classes.gridWrapper}>
            <Container className={classes.portfolio}>
              <Row>
                <Col lg="3" md="3" className="mx-auto">
                  <Fade top triggerOnce={true}>
                    <NavList
                      array={this.state.category}
                      onClick={this.onLinksClickHandler}
                      dark={this.props.dark ? true : false}
                      size="big"
                    />
                  </Fade>
                </Col>
                <Col lg="9" md="9" className={classes.portfolioColumn}>
                  <Row>{this.renderCards()}</Row>
                </Col>
              </Row>

              <Row className={classes.buttonRow}>
                <Col
                  lg={{ size: 8, offset: 4 }}
                  md={{ size: 8, offset: 4 }}
                  className="d-flex justify-content-center"
                >
                  {this.state.postForView <= postForView && (
                    <Fade top delay={200} triggerOnce={true}>
                      <TextButton
                        link={"/link"}
                        text={"Load More"}
                        onClick={this.buttonClickHandler}
                      >
                        <Oval variant="small">
                          <ChevronDown />
                        </Oval>
                      </TextButton>
                    </Fade>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    );
  }
}

export default PortfolioLayoutsTypeThird;

import React, { useState } from "react";
import { Row, Col, Container } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import classes from "./ClientImages.module.css";

function ClientImages(props) {
  const [image, setImage] = useState("https://picsum.photos/id/1/868/468");
  const [caption, setCaption] = useState("Here is caption 1");

  function image1() {
    setImage("https://picsum.photos/id/1/868/468");
    setCaption("Here is caption 1");
  }

  function image2() {
    setImage("https://picsum.photos/id/1003/868/468");
    setCaption("Here is caption 2");
  }

  function image3() {
    setImage("https://picsum.photos/id/1012/868/468");
    setCaption("Here is caption 3");
  }

  function image4() {
    setImage("https://picsum.photos/id/1016/868/468");
    setCaption("Here is caption 4");
  }

  return (
    <React.Fragment>
      <section>
        <div>
          <Container className={classes.container}>
            <Row>
              <Col lg="2" md="2" className={classes.imgPreview}>
                <div className="mb-3">
                  <img
                    onClick={image1}
                    alt=""
                    src="https://picsum.photos/id/1/160/110"
                  />
                  <p className={classes.caption}>{caption}</p>
                </div>
                <div className="mb-3">
                  <img
                    onClick={image2}
                    alt=""
                    src="https://picsum.photos/id/1003/160/110"
                  />
                  <p className={classes.caption}>{caption}</p>
                </div>
                <div className="mb-3">
                  <img
                    onClick={image3}
                    alt=""
                    src="https://picsum.photos/id/1012/160/110"
                  />
                  <p className={classes.caption}>{caption}</p>
                </div>
                <div>
                  <img
                    onClick={image4}
                    alt=""
                    src="https://picsum.photos/id/1016/160/110"
                  />
                  <p className={classes.caption}>{caption}</p>
                </div>
              </Col>
              <Col lg="10" md="10">
                <div className={`ms-3 ${classes.imgHide}`}>
                  <img alt="" src={image} />
                  <p className={classes.caption}>{caption}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ClientImages;

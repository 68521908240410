import React from "react";
import Form from "./SimpleForm/SimpleForm";
import Aside from "./Aside/Aside";

import { Container, Col, Row } from "reactstrap";
import classes from "./FormsLayoutsTypeEighth.module.css";
import { Fade } from "react-awesome-reveal";

function FormsLayoutsTypeEighth(props) {
  return (
    <React.Fragment>
      <section
        className={`${classes.form} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <Container fluid="true" className={classes.containerFluid}>
          <div className={classes.outer}>
            <Container>
              <Row>
                <Col lg="5" md="5" className={classes.leftColumn}>
                  <div
                    className={classes.customWrapper}
                    style={{ height: "100%" }}
                  >
                    <Fade triggerOnce={true}>
                      <Aside
                        title="Get in Touch"
                        description="We're always excited to hear about your projects and ideas, so get in touch and we'll be happy to chat."
                      ></Aside>
                    </Fade>
                  </div>
                </Col>

                <Col
                  lg={{ size: 6, offset: 1 }}
                  md={{ size: 7 }}
                  className={classes.rightColumn}
                >
                  <Form dark={props.dark ? true : false} />
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default FormsLayoutsTypeEighth;

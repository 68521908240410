import React from "react";

import Header from ".././components/Header/Header";
import FeaturesLayoutsTypeSixteen from ".././layouts/FeaturesLayouts/FeaturesLayoutsTypeSixteen/FeaturesLayoutsTypeSixteen";
import CTALayoutsTypeNinth from ".././layouts/CTALayouts/CTALayoutsTypeNinth/CTALayoutsTypeNinth";
import CTALayoutsTypeFirst from ".././layouts/CTALayouts/CTALayoutsTypeFirst/CTALayoutsTypeFirst";
import FormsLayoutsTypeSeventh from ".././layouts/FormsLayouts/FormsLayoutsTypeSeventh/FormsLayoutsTypeSeventh";
import FootersLayoutsTypeSecond from ".././layouts/FootersLayouts/FootersLayoutsTypeSecond/FootersLayoutsTypeSecond";

function Betting() {
  return (
    <div>
      <Header />
      <FeaturesLayoutsTypeSixteen />
      <CTALayoutsTypeNinth />
      <CTALayoutsTypeFirst />
      <FormsLayoutsTypeSeventh />
      <FootersLayoutsTypeSecond />
    </div>
  );
}

export default Betting;

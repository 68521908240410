import React from "react";
import Features from "./Features/Features";

import { Container, Col, Row } from "reactstrap";
import Logos from "./Logos/Logos";
import Divider from "../../../components/Divider/Divider";
import mockup from "../../../img/placeholder-gray.png";
import mockupDark from "../../../img/placeholder-gray-dark.png";
import bettingHeader from "../../../img/betting-and-gaming-ux.png";
import { Fade } from "react-awesome-reveal";
import classes from "./FeaturesLayoutsTypeSixteen.module.css";

const title = "Betting & Gaming Specialists";
const description =
  "We're proud to be preferred design partners with some of the worlds biggest Betting & Gaming operators and sports businesses.";

function FeaturesLayoutsTypeSixteen(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.features} ${
          props.dark ? `dark ${classes.dark}` : "dark"
        }`}
      >
        <Col lg={2} md={2} sm={6} xs={6} className="mx-auto text-center">
          <Fade left triggerOnce={true}>
            <img src={bettingHeader} alt="" width="250" height="250" />
          </Fade>
        </Col>
        <Container className={classes.titleBlock}>
          <Row>
            <Col lg="8" md="8" className="mx-auto text-center">
              <Fade top delay={50} triggerOnce={true}>
                <h2 className={`title ${classes.title}`}>{title}</h2>
              </Fade>
              <Fade top delay={50} triggerOnce={true}>
                <p style={{ display: "block" }}>{description}</p>
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
      <section
        className={`section ${classes.features} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row className={classes.logosRow}>
              <Col>
                <Fade down delay={350} triggerOnce={true}>
                  <Logos dark={props.dark ? true : false} />
                </Fade>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <Fade down delay={300} triggerOnce={true}>
                  <Divider />
                </Fade>
              </Col>
            </Row>
          </Container>
          <Container className={classes.spacingBottom}>
            <Row>
              <Col lg={5} className={`${classes.leftColumn} position-relative`}>
                <Fade down triggerOnce={true}>
                  {props.dark === true ? (
                    <>
                      <img alt="" src={mockupDark} />
                    </>
                  ) : (
                    <>
                      <img alt="" src={mockup} />
                    </>
                  )}
                </Fade>
              </Col>

              <Col lg={7} className={classes.rightColumn}>
                <Features />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default FeaturesLayoutsTypeSixteen;

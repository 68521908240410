import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import "bootstrap/dist/css/bootstrap.css";
import classes from "./ClientDescription.module.css";
import WHlogo from "../../../../img/william-hill-logo.svg";

const title = "The Client";
const clientName = "William Hill US - Retail Sportsbooks";
const subtitle = "Project Background";
const description1 =
  "We worked with William Hill for over 3 years on a long-term project. The challenge: Creating an incredible sports betting experience in bricks and mortar venues across the United States.";
const description2 =
    "The US sports betting market opened up in 2018, following the repeal of PASPA. Understanding and designing for the US Retail Sports Bettor for William Hill was an exciting opportunity.";

function ClientDescription(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.section} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col lg="5" md="6">
                <Fade top triggerOnce={true}>
                  <div className={classes.logoBackground}>
                    <WHlogo />
                  </div>
                </Fade>
              </Col>

              <Col lg="7" md="6" sm="12" className={classes.projectDescription}>
                <Fade top delay={50} triggerOnce={true}>
                  <h3>{title}</h3>
                  <p>{clientName}</p>
                  <h3>{subtitle}</h3>
                  <p>{description1}</p>
                  <p>{description2}</p>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ClientDescription;

import React from "react";

import Header from ".././components/Header/Header";
import FeaturesLayoutsTypeFourth from ".././layouts/FeaturesLayouts/FeaturesLayoutsTypeFourth/FeaturesLayoutsTypeFourth";
import CTALayoutsTypeFirst from ".././layouts/CTALayouts/CTALayoutsTypeFirst/CTALayoutsTypeFirst";

function OurServices() {
  return (
    <div>
      <Header />
      <FeaturesLayoutsTypeFourth />
      <CTALayoutsTypeFirst />
    </div>
  );
}

export default OurServices;

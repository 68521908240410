import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import classes from "./ClientHeader.module.css";
import TextButton from "../../../../components/Text-Button/Text-Button";
import { ArrowRight, ArrowLeft } from "../../../../components/Icons/Icons";
import Oval from "../../../../components/Frame/Frame";

const title = "William Hill";
const description = (
  <React.Fragment>
    User Experience • User Interface
    <span className={classes.descriptionBreak}> • User Research </span>
  </React.Fragment>
);

function ClientHeader(props) {
  return (
    <React.Fragment>
      <section className={`section ${classes.cta} dark`}>
        <Container>
          <div className={classes.gridWrapper}>
            <Row className={classes.titleRow}>
              <Col lg={8} md={8} className="mx-auto text-center">
                <Fade top triggerOnce={true}>
                  <h1 className="title">{title}</h1>
                </Fade>
              </Col>
            </Row>
            <Row className={classes.arrowRow}>
              <Col lg={12} md={12} className={classes.buttons}>
                <div className={classes.leftArrow}>
                  <Fade top delay={100} triggerOnce={true}>
                    <TextButton position="left" link="" text="Previous">
                      <Oval variant="small" color="grey">
                        <ArrowLeft fill="red" />
                      </Oval>
                    </TextButton>
                  </Fade>
                </div>
                <div className={classes.rightArrow}>
                  <Fade top delay={100} triggerOnce={true}>
                    <TextButton position="right" link="" text="Next">
                      <Oval variant="small" color="grey">
                        <ArrowRight />
                      </Oval>
                    </TextButton>
                  </Fade>
                </div>
              </Col>
            </Row>
            <Row className={classes.descriptionRow}>
              <Col lg={8} md={8} className="mx-auto text-center">
                <Fade top delay={50} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {description}
                  </p>
                </Fade>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default ClientHeader;

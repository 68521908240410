import React from "react";
import classes from "./Aside.module.css";
import { Col, Row } from "reactstrap";
import Button from "../../../../components/Button/Button";
import VerticalModal from "../VerticalModal/VerticalModal";
import { Fade } from "react-awesome-reveal";

const strong = "PokerStars Sports";
const title = "Betting Promos Study & Opt-In Journeys";
const description =
  "For our client Stars Group (now Flutter Entertainment) we studied and re-designed their sportsbook promotions proposition ...";

function Aside(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section className={`${classes.aside} ${props.dark ? "dark" : ""}`}>
      <Fade down triggerOnce={true}>
        <strong style={{ display: "block" }}>{strong}</strong>
      </Fade>
      <Fade down delay={100} triggerOnce={true}>
        <h1>{title}</h1>
      </Fade>
      <Fade down delay={200} triggerOnce={true}>
        <p className={`normal-text ${description}`}>{description}</p>
      </Fade>

      <Row className={classes.buttonRow}>
        <Col className={`col-auto ${classes.col}`}>
          <Fade down delay={400} triggerOnce={true}>
            <Button
              link={true}
              href={"/"}
              classes={`primary large simple ${classes.button}`}
              value={"Read More"}
            />
          </Fade>
        </Col>

        <VerticalModal
          dark={props.dark ? true : false}
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </Row>
    </section>
  );
}

export default Aside;

import React from "react";
import { Row, Col } from "reactstrap";

import classes from "./FeaturesTypeTwenty.module.css";

const FeaturesTypeTwenty = (props) => {
  return (
    <div
      className={`${classes.feature}  ${
        props.dark ? `dark ${classes.dark}` : ""
      }`}
    >
      <div className={classes.wrapper}>
        <Row className={classes.topLevel}>
          <Col>{props.children}</Col>
        </Row>
        <Row className={classes.middleLevel}>
          <Col>
            <h3>{props.title}</h3>
            <p className={`normal-text ${classes.description}`}>
              {props.description}
            </p>
          </Col>
        </Row>
        <Row className={classes.bottomLevel}></Row>
      </div>
    </div>
  );
};

export default FeaturesTypeTwenty;

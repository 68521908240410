import React from "react";
// import { Form } from "react-bootstrap";
// import { Col } from "reactstrap";
// import Button from "../../../../components/Button/Button";
// import "antd/dist/antd.css";
// import { Checkbox } from "antd";
import classes from "./SimpleForm.module.css";
// import CustomInput from "../../../../components/CustomInput/CustomInput";

const SimpleForm = (props) => {
  return (
    <React.Fragment>
      <iframe
        title="contact-form"
        className={classes.form}
        src="https://us7.list-manage.com/contact-form?u=3a07a04ac06c658b4f53fd369&form_id=276df385735e2793b0c632dae31733a5"
        scrolling="no"
      ></iframe>
      {/* <Form className={classes.form}>
        <Form.Row>
          <CustomInput
            classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
            controlId="FormInputName"
            dark={props.dark === true ? true : null}
            placeholder="Name"
            name="name"
          />
          <CustomInput
            classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
            controlId="FormInputCompany"
            dark={props.dark === true ? true : null}
            placeholder="Company Name"
            name="companyname"
          />
          <CustomInput
            classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
            controlId="FormInputUserPassword"
            dark={props.dark === true ? true : null}
            placeholder="Email Address"
            name="name"
            type="email"
          />
        </Form.Row>
        <Form.Row>
          <CustomInput
            classes={`col-12 ${classes.p15} ${classes.item} ${props.classes}`}
            controlId="FormInputMessage"
            dark={props.dark === true ? true : null}
            placeholder="Message"
            name="message"
            type="message"
            textarea={true}
            rows="5"
          />
        </Form.Row>
        <Form.Row>
          <Col className={classes.p20}>
            <Checkbox>
              Check if you would also like to sign up to our Newsletter.
            </Checkbox>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col className={`col-12 ${classes.p20}`}>
            <Button
              type={"submit"}
              classes={"primary large simple w-100"}
              value={"Send Message"}
            />
          </Col>
        </Form.Row>
      </Form> */}
    </React.Fragment>
  );
};

export default SimpleForm;

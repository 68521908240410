import React from "react";

import Header from ".././components/Header/Header";
import LegalLayout from ".././layouts/LegalLayouts/LegalLayout";
import FootersLayoutsTypeSecond from ".././layouts/FootersLayouts/FootersLayoutsTypeSecond/FootersLayoutsTypeSecond";

function Privacy() {
  return (
    <div>
      <Header />
      <LegalLayout />
      <FootersLayoutsTypeSecond />
    </div>
  );
}

export default Privacy;

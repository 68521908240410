import React from "react";
import classes from "./CTALayoutsTypeTwelve.module.css";

import Logos from "./Logos/Logos";
import { Container, Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";

const description =
  "Trusted by some of the worlds leading operators and consumer brands ...";
function CTALayoutsTypeTwelve(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.cta} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row className={classes.logosRow}>
              <Col lg={12} md={10} sm={3} className="mx-auto text-center">
                <Fade top triggerOnce={true}>
                  <Logos dark={props.dark ? true : false} />
                </Fade>
              </Col>
            </Row>
            <Row className={classes.textRow}>
              <Col lg={10} md={8} sm={6} className="mx-auto text-center">
                <Fade top delay={50} triggerOnce={true}>
                  <p
                    className={`normal-text text-center ${classes.description}`}
                  >
                    {description}
                  </p>
                </Fade>
              </Col>
            </Row>
            {/*<Row className={classes.buttonsRow}>
                            <Col lg={10} md={10} className="mx-auto d-flex justify-content-center">
                                <Fade top delay={100} triggerOnce={true}>
                                    <Button
                                        link={true}
                                        href={"#"}
                                        classes={`primary large simple ${classes.button}`}
                                        value={"See Case Studies"}
                                    />
                                </Fade>
                            </Col>
                        </Row>*/}
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default CTALayoutsTypeTwelve;

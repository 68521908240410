import React from "react";
import classes from "./SimpleForm.module.css";
import { Form } from "react-bootstrap";
import { Col } from "reactstrap";
import { Email } from "../../../../components/Icons/Icons";
import Button from "../../../../components/Button/Button";
import CustomInput from "../../../../components/CustomInput/CustomInput";

const SimpleForm = (props) => {
  return (
    <React.Fragment>
      <Form
        className={`${classes.form}`}
        action="https://agency.us7.list-manage.com/subscribe/post?u=3a07a04ac06c658b4f53fd369&amp;id=1e01455dd1"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <Form.Row className="align-items-center">
          <CustomInput
            classes={`col-lg-6 col-md-6 col-sm-6 ${classes.item} ${props.classes}`}
            controlId="userEmail"
            name="EMAIL"
            simple={false}
            leftIcon={true}
            icon={<Email />}
            dark={props.dark === true ? true : null}
            type="email"
            placeholder="Type your email"
            class="email"
            id="mce-EMAIL"
          />
          <Col lg="6" md="6" sm="6">
            <Button
              type={"submit"}
              classes={"primary large simple w-100"}
              value={"Subscribe"}
              name="subscribe"
              id="mc-embedded-subscribe"
            />
          </Col>
        </Form.Row>
      </Form>
    </React.Fragment>
  );
};

export default SimpleForm;

import React from "react";
import Header from ".././components/Header/Header";
import ClientHeader from "../layouts/Clients/WilliamHill/WilliamHill-Header/ClientHeader";
import ClientDescription from "../layouts/Clients/WilliamHill/WilliamHill-Description/ClientDescription";
import ClientExperience from "../layouts/Clients/WilliamHill/WilliamHill-Experience/ClientExperience";
import ClientImages from "../layouts/Clients/WilliamHill/WilliamHill-Images/ClientImages";
import ClientTestimonial from "../layouts/Clients/WilliamHill/WilliamHill-Testimonial/ClientTestimonial";
import ClientPagination from "../layouts/Clients/WilliamHill/WilliamHill-Pagination/ClientPagination";
import FootersLayoutsTypeSecond from ".././layouts/FootersLayouts/FootersLayoutsTypeSecond/FootersLayoutsTypeSecond";

function WHClient() {
  return (
    <div>
      <Header />
      <ClientHeader />
      <ClientDescription />
      <ClientExperience />
      <ClientImages />
      <ClientTestimonial />
      <ClientPagination />
      <FootersLayoutsTypeSecond />
    </div>
  );
}

export default WHClient;

import React from "react";
import { Col, Row } from "reactstrap";
import Feature from "../Feature/FeatureTypeFourth";
import {
  Assignment,
  Explore,
  Favorite,
} from "../../../../components/Icons/Icons";
import Oval from "../../../../components/Frame/Frame";
import { Fade } from "react-awesome-reveal";
import classes from "./features.module.css";

const Features = (props) => {
  return (
    <React.Fragment>
      <Row className={classes.featuresRow}>
        <Col lg="4" md="6" className={classes.feature}>
          <Fade top delay={150} triggerOnce={true}>
            <Feature
              title={"Behavioural Insights"}
              description={
                "Helping understand your users through insights - allowing more meaningful journeys to be created."
              }
            >
              <Oval variant={"normal"}>
                <Assignment />
              </Oval>
            </Feature>
          </Fade>
        </Col>
        <Col lg="4" md="6" className={classes.feature}>
          <Fade top delay={200} triggerOnce={true}>
            <Feature
              title={"Generate Loyalty"}
              description={
                "Experts in customer advocacy, key to building long term relationships with your users."
              }
            >
              <Oval variant={"normal"}>
                <Favorite />
              </Oval>
            </Feature>
          </Fade>
        </Col>
        <Col lg="4" md="6" className={classes.feature}>
          <Fade top delay={250} triggerOnce={true}>
            <Feature
              title={"Navigate Best Practices"}
              description={
                "Domain knowledge that will help you design a best-in-class compliant product."
              }
            >
              <Oval variant={"normal"}>
                <Explore />
              </Oval>
            </Feature>
          </Fade>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Features;

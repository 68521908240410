import React from "react";

import { Container, Col, Row } from "reactstrap";
import Divider from "../../components/Divider/Divider";
import TextButton from "../../components/Text-Button/Text-Button";
import classes from "./LegalLayouts.module.css";
import { Fade } from "react-awesome-reveal";

const pageTitle = "Boring Legal Stuff 😴";
const pageDesc =
  "Read all of our legal information for our Privacy Policy, Terms and Cookie Policy here.";
const navPrivacy = "1. Privacy Policy";
const navTerms = "2. Terms of Use";
const navCookie = "3. Cookie Policy";
const titlePrivacy = "Privacy Policy";
const titleTerms = "Terms of Use";
const titleCookie = "Cookie Policy";

function LegalLayout(props) {
  return (
    <React.Fragment>
      <section
        className={`${classes.boringstuff} ${
          props.dark ? `dark ${classes.dark}` : "dark"
        }`}
      >
        <h2 className={"text-center"}>{pageTitle}</h2>
        <p className={"text-center"}>{pageDesc}</p>
      </section>
      <section
        className={`${classes.boringstuff} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <Container fluid="true" className={classes.containerFluid}>
          <div className={classes.outer}>
            <Container>
              <Row>
                <Col
                  lg="3"
                  md="3"
                  sm="12"
                  xs="12"
                  className={classes.leftColumn}
                >
                  <Fade top triggerOnce={true}>
                    <h3>Quick Links</h3>
                    <TextButton link="#privacy" text={navPrivacy}></TextButton>
                    <TextButton link="#terms" text={navTerms}></TextButton>
                    <TextButton link="#cookies" text={navCookie}></TextButton>
                  </Fade>
                </Col>
                <Col
                  lg="9"
                  md="9"
                  sm="12"
                  xs="12"
                  className={classes.rightColumn}
                >
                  <Fade top triggerOnce={true}>
                    <h2 id={"privacy"} className={`title ${classes.title}`}>
                      {titlePrivacy}
                    </h2>
                  </Fade>
                  <Fade top delay={50} triggerOnce={true}>
                    <Divider />
                    <br></br>
                  </Fade>
                  <Fade top delay={50} triggerOnce={true}>
                    <p>
                      Your privacy is important to us. It is Sprocket Design
                      Limited's policy to respect your privacy regarding any
                      information we may collect from you across our website,{" "}
                      <a href="https://sprocket.agency">www.sprocket.agency</a>,
                      and other sites we own and operate.
                    </p>
                    <p>
                      We only ask for personal information when we truly need it
                      to provide a service to you. We collect it by fair and
                      lawful means, with your knowledge and consent. We also let
                      you know why we’re collecting it and how it will be used.
                    </p>
                    <p>
                      We only retain collected information for as long as
                      necessary to provide you with your requested service. What
                      data we store, we’ll protect within commercially
                      acceptable means to prevent loss and theft, as well as
                      unauthorised access, disclosure, copying, use or
                      modification.
                    </p>
                    <p>
                      We don’t share any personally identifying information
                      publicly or with third-parties, except when required to by
                      law.
                    </p>
                    <p>
                      Our website may link to external sites that are not
                      operated by us. Please be aware that we have no control
                      over the content and practices of these sites, and cannot
                      accept responsibility or liability for their respective
                      privacy policies.
                    </p>
                    <p>
                      You are free to refuse our request for your personal
                      information, with the understanding that we may be unable
                      to provide you with some of your desired services.
                    </p>
                    <p>
                      Your continued use of our website will be regarded as
                      acceptance of our practices around privacy and personal
                      information. If you have any questions about how we handle
                      user data and personal information, feel free to contact
                      us.
                    </p>
                    <p>This policy is effective as of 1 December 2020.</p>
                  </Fade>
                  <br></br>
                  <Fade top triggerOnce={true}>
                    <h2 id={"terms"} className={`title ${classes.title}`}>
                      {titleTerms}
                    </h2>
                  </Fade>
                  <Fade top delay={50} triggerOnce={true}>
                    <Divider />
                    <br></br>
                  </Fade>
                  <Fade top delay={50} triggerOnce={true}>
                    <h3>1. Terms</h3>
                    <p>
                      By accessing the website at{" "}
                      <a href="https://sprocket.agency">
                        https://sprocket.agency
                      </a>
                      , you are agreeing to be bound by these terms of service,
                      all applicable laws and regulations, and agree that you
                      are responsible for compliance with any applicable local
                      laws. If you do not agree with any of these terms, you are
                      prohibited from using or accessing this site. The
                      materials contained in this website are protected by
                      applicable copyright and trademark law.
                    </p>
                    <h3>2. Use Licence</h3>
                    <ol type="a">
                      <li>
                        Permission is granted to temporarily download one copy
                        of the materials (information or software) on Sprocket
                        Design Limited's website for personal, non-commercial
                        transitory viewing only. This is the grant of a licence,
                        not a transfer of title, and under this licence you may
                        not:
                        <ol type="i">
                          <li>modify or copy the materials;</li>
                          <li>
                            use the materials for any commercial purpose, or for
                            any public display (commercial or non-commercial);
                          </li>
                          <li>
                            attempt to decompile or reverse engineer any
                            software contained on Sprocket Design Limited's
                            website;
                          </li>
                          <li>
                            remove any copyright or other proprietary notations
                            from the materials; or
                          </li>
                          <li>
                            transfer the materials to another person or "mirror"
                            the materials on any other server.
                          </li>
                        </ol>
                      </li>
                      <li>
                        This licence shall automatically terminate if you
                        violate any of these restrictions and may be terminated
                        by Sprocket Design Limited at any time. Upon terminating
                        your viewing of these materials or upon the termination
                        of this licence, you must destroy any downloaded
                        materials in your possession whether in electronic or
                        printed format.
                      </li>
                    </ol>
                    <h3>3. Disclaimer</h3>
                    <ol type="a">
                      <li>
                        The materials on Sprocket Design Limited's website are
                        provided on an 'as is' basis. Sprocket Design Limited
                        makes no warranties, expressed or implied, and hereby
                        disclaims and negates all other warranties including,
                        without limitation, implied warranties or conditions of
                        merchantability, fitness for a particular purpose, or
                        non-infringement of intellectual property or other
                        violation of rights.
                      </li>
                      <li>
                        Further, Sprocket Design Limited does not warrant or
                        make any representations concerning the accuracy, likely
                        results, or reliability of the use of the materials on
                        its website or otherwise relating to such materials or
                        on any sites linked to this site.
                      </li>
                    </ol>
                    <h3>4. Limitations</h3>
                    <p>
                      In no event shall Sprocket Design Limited or its suppliers
                      be liable for any damages (including, without limitation,
                      damages for loss of data or profit, or due to business
                      interruption) arising out of the use or inability to use
                      the materials on Sprocket Design Limited's website, even
                      if Sprocket Design Limited or a Sprocket Design Limited
                      authorised representative has been notified orally or in
                      writing of the possibility of such damage. Because some
                      jurisdictions do not allow limitations on implied
                      warranties, or limitations of liability for consequential
                      or incidental damages, these limitations may not apply to
                      you.
                    </p>
                    <h3>5. Accuracy of materials</h3>
                    <p>
                      The materials appearing on Sprocket Design Limited's
                      website could include technical, typographical, or
                      photographic errors. Sprocket Design Limited does not
                      warrant that any of the materials on its website are
                      accurate, complete or current. Sprocket Design Limited may
                      make changes to the materials contained on its website at
                      any time without notice. However Sprocket Design Limited
                      does not make any commitment to update the materials.
                    </p>
                    <h3>6. Links</h3>
                    <p>
                      Sprocket Design Limited has not reviewed all of the sites
                      linked to its website and is not responsible for the
                      contents of any such linked site. The inclusion of any
                      link does not imply endorsement by Sprocket Design Limited
                      of the site. Use of any such linked website is at the
                      user's own risk.
                    </p>
                    <h3>7. Modifications</h3>
                    <p>
                      Sprocket Design Limited may revise these terms of service
                      for its website at any time without notice. By using this
                      website you are agreeing to be bound by the then current
                      version of these terms of service.
                    </p>
                    <h3>8. Governing Law</h3>
                    <p>
                      These terms and conditions are governed by and construed
                      in accordance with the laws of UK and you irrevocably
                      submit to the exclusive jurisdiction of the courts in that
                      State or location.
                    </p>
                  </Fade>
                  <br></br>
                  <Fade top triggerOnce={true}>
                    <h2 id={"cookies"} className={`title ${classes.title}`}>
                      {titleCookie}
                    </h2>
                  </Fade>
                  <Fade top delay={50} triggerOnce={true}>
                    <Divider />
                    <br></br>
                  </Fade>
                  <Fade top delay={50} triggerOnce={true}>
                    <p>
                      We want to make it as easy as possible for you to
                      understand and control your online privacy. So here is a
                      clear explanation of what cookies are, how we use them,
                      and how you can manage your preferences when you visit our
                      websites.
                    </p>
                    <h3>What are cookies?</h3>
                    <p>
                      A cookie is a tiny text file placed on your computer,
                      tablet or mobile phone by websites that you visit. Cookies
                      do lots of useful jobs. They help make websites work
                      smoothly, provide information about how people browse, and
                      make sure any adverts you see are as relevant as possible.{" "}
                    </p>
                    <h3>What we use cookies for</h3>
                    <p>
                      Like most websites, Sprocket Agency uses cookies. These
                      cookies are safe and secure and don’t store your personal
                      information. We use them, for example, to:
                    </p>
                    <ol type="b">
                      <li>
                        Preserve your Sprocket Agency log in state and remember
                        any settings and preferences.
                      </li>
                      <li>
                        Show the most useful content for you, including articles
                        and adverts about Sprocket Agency products and services.
                        This means you might see our adverts on other websites.
                      </li>
                      <li>
                        Understand who visits our websites, how they navigate
                        around them and how easy they find them to use.
                      </li>
                    </ol>
                    <h3>How do I change my cookie settings?</h3>
                    <p>
                      Our websites use three kinds of cookies. Some are strictly
                      necessary for making our website work well. But you can
                      choose to turn all the others on or off below. You can
                      also manage what cookies are stored on your computer
                      directly by setting your browser to accept or reject
                      certain cookies. Blocking some types of cookies may mean
                      you can’t use all the services or features on our sites.
                    </p>
                    <p>
                      Third-party cookies are set by someone other than the
                      owner of the website you’re visiting. Some of our pages
                      may also contain content from other sites like YouTube,
                      which may set their own cookies. If you share a link to a
                      page on one of our sites, the platform you share it on
                      (for example, Facebook) may set a cookie on your browser.
                      We have no control over third-party cookies – you can turn
                      them off, but not through us.
                    </p>
                    <p>
                      Do not track (DNT) is a feature offered by some browsers
                      which, if enabled, sends a signal to websites requesting
                      that your browsing isn’t tracked. Currently there is no
                      industry-wide standard that has been agreed or universally
                      adopted to determine how such signals are handled and for
                      that reason we do not respond to DNT requests. We will
                      review how this feature, and other technologies similar,
                      evolve and then take steps to incorporate as and when
                      appropriate.
                    </p>
                    <p>
                      Remember that editing your cookie preferences will not
                      remove any existing cookies from your browser. It’ll only
                      affect the way cookies are used in future.
                    </p>
                    <h3>1. Essential and Performance Cookies</h3>
                    <p>
                      Some cookies are essential for the basic functionality of
                      our websites. They let you do things like log in, move
                      from page to page, and use our interactive tools. You
                      can’t choose to turn off these cookies. Without them, our
                      website won’t work properly and we won’t be able to fix
                      any errors.
                    </p>
                    <p>
                      We also use cookies to run essential analytics and testing
                      tools. These tools measure and improve how our website
                      performs and makes sure that it meets your needs, and that
                      we are always providing the best experience possible for
                      our users.
                    </p>
                    <h3>2. User Experience & Insight Cookies</h3>
                    <p>
                      These cookies help us get feedback so we can improve what
                      we offer. They’re needed to run reporting tools that
                      gather information about how people are using our website.
                      You can choose to turn these cookies on or off at any
                      time.
                    </p>
                    <h3>3. Advertising & Targeting Cookies</h3>
                    <p>
                      We never show adverts from third parties on our website.
                      However, we do advertise our own products and services,
                      and you may see our adverts served around the internet by
                      third party services.
                    </p>
                    <p>
                      We use cookies to measure how well our adverts perform and
                      to make sure they’re useful to you – based on what kind of
                      content you interact with and read the most.
                    </p>
                    <p>
                      Some cookies tell us where you saw a particular advert and
                      help us limit the number of times you see it.
                    </p>
                    <p>
                      We also use cookies to make the content in our emails and
                      newsletters more relevant to you.
                    </p>
                  </Fade>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default LegalLayout;

import React from "react";
import classes from "./FeaturesLayoutsTypeFourth.module.css";

import { Container, Col, Row } from "reactstrap";
import Features from "./Features/Features";

import placeholderA from "../../../img/placeholder-grayA.png";
import placeholderB from "../../../img/placeholder-grayB.png";
import placeholderDark from "../../../img/placeholder-gray-dark.png";
import { Fade } from "react-awesome-reveal";
const description = "Betting Industry Specialists";
const title = "Deep domain knowledge that gives you an edge.";

function FeaturesLayoutsTypeFourth(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.features} ${
          props.dark ? `dark ${classes.dark}` : "dark"
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row className={classes.titleRow}>
              <Col lg="9" md="9" className={classes.leftColumn}>
                <Fade top delay={50} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {description}
                  </p>
                </Fade>
                <Fade top triggerOnce={true}>
                  <h1 className={`title ${classes.title}`}>{title}</h1>
                </Fade>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row>
              <Col className={classes.centerContainer}>
                {props.dark === true ? (
                  <>
                    <Fade top delay={100} triggerOnce={true}>
                      <img
                        width="1100"
                        height="320"
                        src={placeholderDark}
                        alt=""
                      />
                    </Fade>
                  </>
                ) : (
                  <Row>
                    <Col lg="6" md="6" sm="12">
                      <Fade top delay={100} triggerOnce={true}>
                        <img
                          width="auto"
                          height="auto"
                          src={placeholderA}
                          alt=""
                        />
                      </Fade>
                    </Col>
                    <Col lg="6" md="6" sm="12" className={classes.hidemob}>
                      <Fade top delay={100} triggerOnce={true}>
                        <img
                          width="auto"
                          height="auto"
                          src={placeholderB}
                          alt=""
                        />
                      </Fade>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Container>

          <Container>
            <Features />
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default FeaturesLayoutsTypeFourth;

import React from "react";

import Header from ".././components/Header/Header";
import FormsLayoutsTypeEighth from ".././layouts/FormsLayouts/FormsLayoutsTypeEighth/FormsLayoutsTypeEighth";
import FootersLayoutsTypeSecond from ".././layouts/FootersLayouts/FootersLayoutsTypeSecond/FootersLayoutsTypeSecond";

function Contact() {
  return (
    <div>
      <Header />
      <FormsLayoutsTypeEighth />
      <FootersLayoutsTypeSecond />
    </div>
  );
}

export default Contact;

import React from "react";
import "./CookieBar.css";
import CookieConsent from "react-cookie-consent";

const CookieBar = (props) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="ACCEPT"
      declineButtonText="DECLINE"
      enableDeclineButton
      cookieName="sprocketAgencyCookie"
      expires={365}
      flipButtons={true}
      onDecline={() => {
        window.location.href = "https://google.com";
      }}
    >
      <p className={"cookiestitle"}>COOKIES</p>
      Sprocket Agency uses cookies to enhance your user experience.
    </CookieConsent>
  );
};

export default CookieBar;

import React from "react";

import { Container, Col, Row } from "reactstrap";
import { ArrowRight } from "../../../components/Icons/Icons";
import Button from "../../../components/Button/Button";

import firstMockup from "../../../img/IphoneXS.png";
import secondMockup from "../../../img/IpadPro.png";
import firstMockupDark from "../../../img/IphoneXS-dark.png";
import secondMockupDark from "../../../img/IpadPro-dark.png";

import classes from "./HeroLayoutsTypeSixth.module.css";
import { Fade } from "react-awesome-reveal";

const titleText = "Digital Experience at it’s finest.";
const descriptionText =
  "Over 20 years experience and knowledge in Brand, Content and User Experience Design.";
const baseLinkText = "Read More";

function HeroLayoutsTypeSixth(props) {
  return (
    <React.Fragment>
      <section
        className={`${classes.hero} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row className={classes.contentRow}>
              <Col lg="12" md="12" className="position-relative">
                <Fade right delay={50} triggerOnce={true}>
                  {props.dark === true ? (
                    <>
                      <img
                        src={firstMockupDark}
                        style={{ top: "40px", right: "230px", zIndex: "999" }}
                        alt=""
                        className={`${classes.firstImage} position-absolute`}
                        width="350"
                        height="697"
                      />
                      <img
                        style={{ top: "-80px", right: "-800px" }}
                        src={secondMockupDark}
                        alt=""
                        className={`${classes.secondImage} position-absolute`}
                        width="1286"
                        height="984"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={firstMockup}
                        style={{ top: "40px", right: "230px", zIndex: "999" }}
                        alt=""
                        className={`${classes.firstImage} position-absolute`}
                        width="350"
                        height="697"
                      />
                      <img
                        style={{ top: "-80px", right: "-800px" }}
                        src={secondMockup}
                        alt=""
                        className={`${classes.secondImage} position-absolute`}
                        width="1286"
                        height="984"
                      />
                    </>
                  )}
                </Fade>
              </Col>

              <Col lg="5" md="6" className={classes.rightColumn}>
                <Fade top delay={50} triggerOnce={true}>
                  <h1>{titleText}</h1>
                </Fade>
                <Fade top delay={100} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {descriptionText}
                  </p>
                </Fade>
                <Fade top delay={150} triggerOnce={true}>
                  <div className={classes.buttonElement}>
                    <Button
                      link={true}
                      href={"/betting"}
                      classes={"secondary large right-icon"}
                      value={baseLinkText}
                      rightIcon={<ArrowRight fill="#ffffff" />}
                    />
                  </div>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default HeroLayoutsTypeSixth;

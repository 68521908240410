import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CookieBar from "./components/CookieBar/CookieBar";

import Home from "./pages/Home";
import Betting from "./pages/Betting";
import Work from "./pages/Work";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import WHClient from "./pages/WHClient";
import OurServices from "./pages/OurServices";

class App extends Component {
  render() {
    return (
      <div>
        <CookieBar />
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/betting" component={Betting} />
            <Route path="/work" component={Work} />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/william-hill" component={WHClient} />
            <Route path="/our-services" component={OurServices} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;

import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import { Avatar } from "antd";
import { User } from "../../../../components/Icons/Icons";
import classes from "./ClientTestimonial.module.css";

const title = "Testimonial";
const description =
  '"Sprocket Agency has helped us understand our customers and identify their needs. Using their extensive industry knowledge, they have provided powerful design solutions that set us up to transform the US retail betting experience."';

const shortDescription = "Powerful Design Solutions";
const name = "Bryan Brown";
const nameDesc = "Product Analyst, William Hill US";

function ClientTestimonial(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.form} ${
          props.dark ? `dark ${classes.dark}` : ""
        }`}
      >
        <div className={classes.wrapper}>
          <Container>
            <Row>
              <Col lg={8} md={8} className="mx-auto text-center">
                <Fade down triggerOnce={true}>
                  <h4 className={classes.title}>{title}</h4>
                </Fade>
                <Fade down triggerOnce={true}>
                  <h2 className={classes.shortDescription}>
                    {shortDescription}
                  </h2>
                </Fade>
                <Fade down delay={50} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {description}
                  </p>
                </Fade>
                <Fade down delay={50} triggerOnce={true}>
                  <Avatar
                    size={60}
                    icon={<User fill="#ffffff" />}
                    style={{
                      fontSize: 0,
                      backgroundColor: "#6236FF",
                      margin: "20px auto",
                    }}
                  />
                </Fade>
                <Fade down triggerOnce={true}>
                  <h4 className={classes.title}>{name}</h4>
                </Fade>
                <Fade down triggerOnce={true}>
                  <p className={classes.nameDescription}>{nameDesc}</p>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default ClientTestimonial;

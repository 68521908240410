import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import classes from "./ClientExperience.module.css";

const title = "How we solved the problem";
const text = (
  <React.Fragment>
    <p className={classes.spacing}>
      <i>Following an extensive initial discovery phase, we were brought on-board to
      work with the project team to understand and progress a number of opportunities:</i>
    </p>
    <p className={classes.spacing}>
      <ol>
        <li className={classes.spacing}>To help evaluate a number of ergonomic factors surrounding the hardware and peripherals that were selected for use on both the Kiosk and POS units to be used in Sportsbooks cross venues in multiple US states.</li>
        <li className={classes.spacing}>To research both internal and external users of the software that required designing to indentify requirements.</li>
        <li className={classes.spacing}>Design and validate the solution for Kiosk, POS and Back Office software.</li>
      </ol>
    </p>
    <p className={classes.spacing}>
      By travelling to the states that William Hill were planning on delivering their retail solution, we were able to establish a really rich understanding of the user-base for each product set and their intersections.
    </p>
    <p className={classes.spacing}>After 3 years of working with William Hill, we have helped design and launch into West Virginia, Iowa, Indiana and Mississippi. With many more states to follow in quick succession.</p>
  </React.Fragment>
);

function ClientExperience(props) {
  return (
    <React.Fragment>
      <section className={`${props.dark ? `dark ${classes.dark}` : ""}`}>
        <Container fluid="true" className={classes.containerFluid}>
          <div className={classes.outer}>
            <Container>
              <Row>
                <Col lg="12" md="12">
                  <Fade triggerOnce={true}>
                    <h2 className={classes.spacing}>{title}</h2>
                    <p>{text}</p>
                  </Fade>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default ClientExperience;

import React from "react";

import { Container, Col, Row } from "reactstrap";

import {
  SprocketLogotypeDark,
  LinkedIn,
  Twitter,
} from "../../../components/Icons/Icons";
import Socials from "../../../components/Socials/Socials";
import Divider from "../../../components/Divider/Divider";
import classes from "./FootersLayoutsTypeSecond.module.css";
import { Fade } from "react-awesome-reveal";

const description = "Sports, betting & gaming user experience specialists.";

function FootersLayoutsTypeSecond(props) {
  return (
    <React.Fragment>
      <section
        className={`section  ${classes.footer} ${
          props.dark ? `dark ${classes.dark}` : "dark"
        }`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col lg="3" md="4">
                <Fade top triggerOnce={true}>
                  <a href="/" className={classes.logotype}>
                    <SprocketLogotypeDark />
                  </a>
                </Fade>
                <Fade top delay={50} triggerOnce={true}>
                  <p className={classes.description}>{description}</p>
                </Fade>
                <Fade top delay={100} triggerOnce={true}>
                  <a
                    href="mailto:hello@sprocket.agency"
                    className={`Button primary small simple ${classes.button}`}
                  >
                    <span>Get in Touch</span>
                  </a>
                </Fade>
              </Col>
              <Col
                lg={{ size: 7, offset: 2 }}
                md={{ size: 7, offset: 1 }}
                className={classes.rightColumn}
              >
                <Row>
                  <Col lg="4" md="6" sm="5" xs="6" className={classes.item}>
                    <Fade top delay={100} triggerOnce={true}>
                      <header>Quick Links</header>
                      <ul className={classes.companyList}>
                        <li className={classes.companyItem}>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="#">Betting & Gaming UX</a>
                        </li>
                        <li className={classes.companyItem}>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="#">Case Studies</a>
                        </li>
                        <li className={classes.companyItem}>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="#">Whitepapers</a>
                        </li>
                        <li className={classes.companyItem}>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="#">Research</a>
                        </li>
                        <li className={classes.companyItem}>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <a href="#">Betting Wireframes Kit</a>
                        </li>
                      </ul>
                    </Fade>
                  </Col>
                  <Col lg="3" md="6" sm="4" xs="6" className={classes.item}>
                    <Fade top delay={150} triggerOnce={true}>
                      <header>Legals</header>
                      <ul className={classes.companyList}>
                        <li className={classes.companyItem}>
                          <a href="/privacy">Privacy</a>
                        </li>
                        <li className={classes.companyItem}>
                          <a href="/privacy#terms">Terms</a>
                        </li>
                        <li className={classes.companyItem}>
                          <a href="/privacy#cookies">Cookie Policy</a>
                        </li>
                      </ul>
                    </Fade>
                  </Col>
                  <Col lg="5" md="8" className={classes.item}>
                    <Fade top delay={200} triggerOnce={true}>
                      <header>Contact</header>
                      <div className={classes.content}>
                        <p className={classes.shortDescription}>
                          Feel free get in touch. We're happy to chat over the
                          phone or drop us an email.
                        </p>
                        <a
                          href="tel:+44 20 8123 7275"
                          className={classes.boldLink}
                        >
                          +44 20 8123 7275
                        </a>
                        <a
                          href="mailto:hello@sprocket.agency"
                          className={classes.boldLink}
                        >
                          hello@sprocket.agency
                        </a>
                      </div>
                    </Fade>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className={classes.dividerRow}>
              <Col>
                <Fade top delay={250} triggerOnce={true}>
                  <div className={classes.divider}>
                    <Divider />
                  </div>
                </Fade>
              </Col>
            </Row>
            <Row>
              <Col className="col-auto">
                <Fade top delay={300} triggerOnce={true}>
                  <p className={classes.copyright}>
                    Copyright © 2020-2021 Sprocket Design Limited
                  </p>
                </Fade>
              </Col>
              <Col
                lg="3"
                md="3"
                sm="4"
                className={`ml-auto d-flex align-items-center justify-content-end ${classes.socials}`}
              >
                <Fade top delay={350} triggerOnce={true}>
                  <Socials dark={true}>
                    <a href="https://twitter.com/sprocketagency">
                      <Twitter />
                    </a>
                    <a href="https://linkedin.com/company/sprocket-agency">
                      <LinkedIn />
                    </a>
                  </Socials>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default FootersLayoutsTypeSecond;

import React from "react";
import classes from "./CTALayoutsTypeSecond.module.css";
import TextButton from "../../../components/Text-Button/Text-Button";
import { RightArrow } from "../../../components/Icons/Icons";
import Oval from "../../../components/Frame/Frame";
import { Fade } from "react-awesome-reveal";
import { Container, Col, Row } from "reactstrap";

const title = "Experience our best work";
const description =
  "We like to let our work do the talking, but if you are looking for something specific, reach out and we'll arrange an introduction.";

function CTALayoutsTypeSecond(props) {
  return (
    <React.Fragment>
      <section
        className={`section ${classes.cta} ${props.dark ? "dark" : "dark"}`}
      >
        <div className={classes.gridWrapper}>
          <Container>
            <Row>
              <Col lg={8} md={8} className="mx-auto text-center">
                <Fade top triggerOnce={true}>
                  <h1 className="title">{title}</h1>
                </Fade>
                <Fade top delay={50} triggerOnce={true}>
                  <p className={`normal-text ${classes.description}`}>
                    {description}
                  </p>
                </Fade>
              </Col>
            </Row>
            <Row className={classes.buttonRow}>
              <Col
                lg={8}
                md={8}
                className="mx-auto d-flex justify-content-center"
              >
                <Fade top delay={100} triggerOnce={true}>
                  <TextButton
                    position={"right"}
                    link={"/contact"}
                    text={"Contact Us"}
                  >
                    <Oval variant={"small"}>
                      <RightArrow />
                    </Oval>
                  </TextButton>
                </Fade>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </React.Fragment>
  );
}
export default CTALayoutsTypeSecond;

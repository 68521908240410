import React from "react";
import { Col, Row } from "reactstrap";
import Feature from "../Feature/FeaturesTypeFirst";
import { Fade } from "react-awesome-reveal";
import classes from "./Features.module.css";

const Features = (props) => {
  return (
    <React.Fragment>
      <Row className={classes.featuresRow}>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down triggerOnce={true}>
            <Feature
              title={"US Market Focus"}
              description={
                "Early grasp of the US betting opportunity and user-base"
              }
            />
          </Fade>
        </Col>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={50} triggerOnce={true}>
            <Feature
              title={"Research Led"}
              description={
                "Leading with research and user testing is baked into our approach"
              }
            />
          </Fade>
        </Col>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={100} triggerOnce={true}>
            <Feature
              title={"Day 1 Design Systems"}
              description={
                "From day one we lead projects starting with design systems"
              }
            />
          </Fade>
        </Col>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={150} triggerOnce={true}>
            <Feature
              title={"Technically Integrated"}
              description={
                "Not only great design but superb technical integration capabilities"
              }
            />
          </Fade>
        </Col>

        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={200} triggerOnce={true}>
            <Feature
              title={"Customer Centric"}
              description={
                "We encourage a customer first approach for all of our clients"
              }
            />
          </Fade>
        </Col>
        <Col lg={6} md={6} className={classes.item}>
          <Fade down delay={250} triggerOnce={true}>
            <Feature
              title={"Sector-Specific Expertis"}
              description={
                "From user personas to industry recognised betting lingo"
              }
            />
          </Fade>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Features;

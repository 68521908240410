import React from "react";

import Header from ".././components/Header/Header";
import HeroLayoutsTypeSixth from ".././layouts/HeroLayouts/HeroLayoutsTypeSixth/HeroLayoutsTypeSixth";
import CTALayoutsTypeTwelve from ".././layouts/CTALayouts/CTALayoutsTypeTwelve/CTALayoutsTypeTwelve";
import FeaturesLayoutsTypeFourth from ".././layouts/FeaturesLayouts/FeaturesLayoutsTypeFourth/FeaturesLayoutsTypeFourth";
import CTALayoutsTypeFirst from ".././layouts/CTALayouts/CTALayoutsTypeFirst/CTALayoutsTypeFirst";
import FeaturesLayoutsTypeFourteen from ".././layouts/FeaturesLayouts/FeaturesLayoutsTypeFourteen/FeaturesLayoutsTypeFourteen";
import FormsLayoutsTypeSeventh from ".././layouts/FormsLayouts/FormsLayoutsTypeSeventh/FormsLayoutsTypeSeventh";
import FootersLayoutsTypeSecond from ".././layouts/FootersLayouts/FootersLayoutsTypeSecond/FootersLayoutsTypeSecond";

function Home() {
  return (
    <div>
      <Header />
      <HeroLayoutsTypeSixth />
      <CTALayoutsTypeTwelve />
      <FeaturesLayoutsTypeFourth />
      <CTALayoutsTypeFirst />
      <FeaturesLayoutsTypeFourteen />
      <FormsLayoutsTypeSeventh />
      <FootersLayoutsTypeSecond />
    </div>
  );
}

export default Home;

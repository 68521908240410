import React from "react";
import { Container, Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";
import classes from "./ClientPagination.module.css";
import TextButton from "../../../../components/Text-Button/Text-Button";
import { ArrowRight, ArrowLeft } from "../../../../components/Icons/Icons";
import Oval from "../../../../components/Frame/Frame";

function ClientPagination(props) {
  return (
    <React.Fragment>
      <section className={`${classes.cta}`}>
        <Container>
          <Row>
            <Col lg={12} md={12} className={classes.buttons}>
              <div className={classes.leftArrow}>
                <Fade top delay={100} triggerOnce={true}>
                  <TextButton position="left" link="" text="Previous Project">
                    <Oval variant="small" color="navy">
                      <ArrowLeft />
                    </Oval>
                  </TextButton>
                </Fade>
              </div>
              <div className={classes.rightArrow}>
                <Fade top delay={100} triggerOnce={true}>
                  <TextButton position="right" link="" text="Next Project">
                    <Oval variant="small" color="navy">
                      <ArrowRight />
                    </Oval>
                  </TextButton>
                </Fade>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}
export default ClientPagination;
